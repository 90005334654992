import { PasswordGrantCredentials } from './validation';

export type LoginCredentials =
  | MagicLinkCredentials
  | OobGrantCredentials
  | PasswordGrantCredentials
  | GoogleGrantCredentials
  | AppleCredentials;

export type SSOCredentials = AppleCredentials | GoogleGrantCredentials;

export enum MagicLinkType {
  LOGIN = 'login',
  SIGNUP = 'signup',
}

export type AppleCredentials = {
  appleAccessToken: string;
  appleUserId: string;
};

export type MagicLinkCredentials = {
  magicLinkToken: string;
};

export type OobGrantCredentials = {
  mfaToken: string;
  challengeId: string;
  bindingCode: string;
};

export type GoogleGrantCredentials = {
  googleIdToken: string;
};

export type LoginResponse = {
  token: string;
};

export type MfaRequiredResponse = {
  error: 'MFA_REQUIRED';
  error_description: string;
  mfa_token: string;
};

export type LoginErrorResponse = {
  code: number;
  error: string;
  message: string;
  id: string;
  detail: string;
  error_message: string;
  error_description: string;
};

export type CombinedLoginErrorResponse =
  | LoginErrorResponse
  | MfaRequiredResponse;

export enum LoginType {
  Facebook = 'facebook',
  UsernameEmail = 'usernameEmail',
  Google = 'google',
  Apple = 'apple',
  MagicLink = 'magicLink',
  AppleSignup = 'apple_signup',
  GoogleSignup = 'google_signup',
}

export type AccountLinkingCredential =
  | GoogleGrantCredentials
  | {
      appleId: string;
      appleAccessToken: string;
    }
  | undefined;

export function isGoogleGrantCredential(
  credential: LoginCredentials
): credential is GoogleGrantCredentials {
  if (
    typeof credential === 'object' &&
    Object.hasOwn(credential, 'googleIdToken')
  ) {
    return true;
  }

  return false;
}

export function isAppleCredentials(
  credential: LoginCredentials
): credential is AppleCredentials {
  if (
    typeof credential === 'object' &&
    Object.hasOwn(credential, 'appleAccessToken')
  ) {
    return true;
  }

  return false;
}

export function isLoginError(error: unknown): error is LoginErrorResponse {
  if (typeof error !== 'object' || error === null) {
    return false;
  }

  const errorKeys = new Set(Object.keys(error));

  return [
    'code',
    'error',
    'message',
    'id',
    'detail',
    'error_message',
    'error_description',
  ].every((field) => errorKeys.has(field));
}

export enum RecoveryOptions {
  RESEND = 'resend',
  RECOVERY = 'recovery',
  HELP = 'help',
}

type MFADeviceType = 'oob' | 'recovery-code';

export type MFAChallengeResponse = {
  challengeType: MFADeviceType;
  bindingMethod: 'prompt';
  challengeId: string;
  challengesRemaining: number;
};

export type MFADevice = {
  id: string;
  mfaDeviceType: MFADeviceType;
  validated: boolean;
  countryCode: string;
  phoneNumber: string;
  oobChannel?: 'sms';
  isDefault?: boolean;
  enableOnValidated?: boolean;
};
