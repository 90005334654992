import { User } from '../user/types';

import { AccountLinkingCredential, LoginType } from './types';

import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useReferralTracker } from '@/modules/activityTracker/useReferralTracker';
import { useExternalTracking } from '@/modules/externalTracking/useExternalTracking';
import { useSetWebConsent } from '@/modules/trackingProviders/useSetWebConsent';
import { useTrackingProviders } from '@/modules/trackingProviders/useTrackingProviders';
import { segmentEventTypes } from '@/modules/externalTracking/types';
import {
  ActivityTrackerEventType,
  referralEventTypes,
} from '@/modules/activityTracker/constants';
import { loginSignupCta } from '@/modules/login/constants.ts';

export function useLoginTracking() {
  const { sendActivityTrackerEvent, reinitialiseATSession } =
    useActivityTracker();
  const { ga, branch, segment } = useExternalTracking();
  const trackReferralEvent = useReferralTracker();
  const { getCurrentConsentChoices } = useTrackingProviders();
  const { postWebConsent } = useSetWebConsent();

  async function sendLoginEvents(user: User, loginType: LoginType) {
    const { id: userId, username, email } = user;

    const segmentIdentifyData = {
      userId: userId.toString(),
      username,
      email,
    };
    const segmentData = {
      userId: userId.toString(),
      username,
    };

    segment.identifyUser(userId.toString(), segmentIdentifyData);
    segment.sendEvent(segmentEventTypes.SIGNED_IN, segmentData);
    ga.sendEvent({
      category: 'Login',
      action: 'confirm signin',
      label: loginType === LoginType.UsernameEmail ? 'web' : loginType,
    });

    postWebConsent(getCurrentConsentChoices());

    await Promise.allSettled([
      sendActivityTrackerEvent(
        ActivityTrackerEventType.LOGIN_SUCCESS_ACTION,
        {
          loginType,
          schemaVersion: '3.0',
        },
        { baseEventOverrides: { userId } }
      ),
      branch.identifyUser(userId),
      trackReferralEvent(referralEventTypes.LOGIN, { userId }),
      reinitialiseATSession([], userId),
    ]);
  }

  function sendLoginFailureEvents(loginType: LoginType) {
    /** Should we add a login failure AT event - doesn't currently exist? */
    ga.sendEvent({
      category: 'Login',
      action: 'failed signin',
      label: loginType,
    });
  }

  function sendLoginSubmitAction(loginType: LoginType) {
    sendActivityTrackerEvent(ActivityTrackerEventType.LOGIN_SUBMIT_ACTION, {
      loginType,
      schemaVersion: '3.0',
    });
  }

  function sendSignupCtaAction() {
    sendActivityTrackerEvent(ActivityTrackerEventType.CTA_ACTION, {
      type: loginSignupCta,
      schemaVersion: '2.0',
    });
  }

  function sendWelcomeCtaAction() {
    sendActivityTrackerEvent(ActivityTrackerEventType.LOGIN_WELCOME_ACTION, {
      actionType: 'usernameEmail',
    });
  }

  function sendMagicLinkUsePasswordAction() {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.LOGIN_MAGIC_LINK_EDUCATION_USE_PASSWORD_ACTION,
      {
        schemaVersion: '1.0',
      }
    );
  }

  function sendLinkAccountSuccessEvent(credential: AccountLinkingCredential) {
    const thirdParty =
      typeof credential === 'object' &&
      Object.hasOwn(credential, 'appleAccessToken')
        ? LoginType.Apple
        : LoginType.Google;

    sendActivityTrackerEvent(ActivityTrackerEventType.LINK_ACCOUNT_SUCCESS, {
      schemaVersion: '1.0',
      thirdParty,
    });
  }

  function sendLoginViewEvent() {
    sendActivityTrackerEvent(ActivityTrackerEventType.WELCOME_VIEW);
  }

  return {
    sendLoginViewEvent,
    sendLoginEvents,
    sendLoginFailureEvents,
    sendLoginSubmitAction,
    sendMagicLinkUsePasswordAction,
    sendSignupCtaAction,
    sendLinkAccountSuccessEvent,
    sendWelcomeCtaAction,
  };
}
